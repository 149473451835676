import React from "react";
import { ControlPatientsTable, EmployeesTable, Header } from "../components";

const ControlPatients = () => {
  return (
    <>
      <Header />
      <div className="px-20 mt-20">
        <ControlPatientsTable />
      </div>
    </>
  );
};

export default ControlPatients;
