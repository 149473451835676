import React, { useState } from "react";

const PatientsFilter = ({ onChangeFilter, filterLabels, setFilterLabels }) => {
  const resetFilter = () => {
    const emptyObject = Object.fromEntries(
      Object.entries(filterLabels).map(([key]) => [key, ""])
    );
    setFilterLabels(emptyObject);
  };
  return (
    <>
      <div>
        <div className="flex gap-8 mx-auto">
          <div>
            <div>
              <label className="sr-only">Nume</label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                className="pl-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Nume"
                value={filterLabels.lastName}
                onChange={onChangeFilter}
              />
            </div>
          </div>
          <div>
            <div>
              <label className="sr-only">Prenume</label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                className="pl-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Prenume"
                value={filterLabels.firstName}
                onChange={onChangeFilter}
              />
            </div>
          </div>
          <div>
            <div>
              <label className="sr-only">CNP</label>
              <input
                type="text"
                name="cnp"
                id="cnp"
                className="pl-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="CNP"
                value={filterLabels.cnp}
                onChange={onChangeFilter}
              />
            </div>
          </div>
          <div>
            <div>
              <label className="sr-only">Telefon</label>
              <input
                type="text"
                name="phone"
                id="phone"
                className="pl-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Telefon"
                value={filterLabels.phone}
                onChange={onChangeFilter}
              />
            </div>
          </div>
          <div>
            <div>
              <label className="sr-only">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                className="pl-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Email"
                value={filterLabels.email}
                onChange={onChangeFilter}
              />
            </div>
          </div>
          <div>
            <div>
              <label className="sr-only">Numar consultatie</label>
              <input
                type="text"
                name="consultationNumber"
                id="consultationNumber"
                className="pl-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Numar consultatie"
                value={filterLabels.consultationNumber}
                onChange={onChangeFilter}
              />
            </div>
          </div>
          <div>
            <div>
              <button
                type="button"
                onClick={(e) => resetFilter()}
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Reseteaza filtre
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PatientsFilter;
