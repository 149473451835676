import axios from "axios";

export const getPatients = async (offset, limit, filterLabels) => {
  const res = await axios.get(`${process.env.REACT_APP_DEV_HOST}/pacients`, {
    params: {
      offset: offset,
      limit: limit,
      first_name: filterLabels.lastName,
      last_name: filterLabels.firstName,
      code: filterLabels.cnp,
    },
  });
  return res.data;
};

export const createPatient = async () => {
  const res = await axios.post(`${process.env.REACT_APP_DEV_HOST}/pacients`, {
    data: {
      pacient_data: {
        first_name: "Test1",
        last_name: "Test2",
        code: "1231231",
      },
      contact_data: {
        phone: "0751169037",
        email: null,
        obs: null,
      },
    },
  });
  console.log(res);
  return res.data;
};
