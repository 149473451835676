import "./App.css";
import { Route, Routes } from "react-router-dom";
import { ControlPatients, Patient, Patients, WaitingList } from "./pages";
import Employees from "./pages/Employees";

function App() {
  return (
    <Routes>
      <Route path="/" exact element={<Patients />} />
      <Route path="/patients" exact element={<Patients />} />
      <Route path="/patient/:id" exact element={<Patient />} />
      <Route path="/waitinglist" exact element={<WaitingList />} />
      <Route path="/employees" exact element={<Employees />} />
      <Route path="/controlpatients" exact element={<ControlPatients />} />
    </Routes>
  );
}

export default App;
