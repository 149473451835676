import React from "react";

const Loading = () => {
  return (
    <div className="m-auto h-full position-aboslute flex justify-center my-3">
      <div className="animate-spin rounded-full h-32 w-32 border-b-4"></div>
    </div>
  );
};

export default Loading;
