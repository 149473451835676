import axios from "axios";

export const getEmployees = async (offset, limit, filterLabels) => {
  const res = await axios.get(`${process.env.REACT_APP_DEV_HOST}/users`, {
    /* Decomentam daca adaugam filtre la request*/
    // params: {
    //   offset: offset,
    //   limit: limit,
    //   first_name: filterLabels.lastName,
    //   last_name: filterLabels.firstName,
    //   code: filterLabels.cnp,
    // },
  });
  return res.data;
};
