import React from "react";
import { EmployeesTable, Header } from "../components";

const Employees = () => {
  return (
    <>
      <Header />
      <div className="px-20">
        <EmployeesTable />
      </div>
    </>
  );
};

export default Employees;
