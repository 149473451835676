import React, { useEffect, useState } from "react";
import { getPatients } from "../api/Patients";
import { nrPerPage } from "../utils/Constants";
import Loading from "./Loading";
import { Link } from "react-router-dom";
import { getEmployees } from "../api/Employees";
import {
  getControlPatients,
  onPressButtonControlPatients,
  test,
} from "../api/ControlPatients";

const ControlPatientsTable = () => {
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    /* Schimbam valoril de null cu cele din filtre daca este cazul*/
    getControlPatients(null, null, null).then((res) => {
      setPatients(res);
      setLoading(false);
    });
  }, []);

  const onPressButton = (e, id, mode) => {
    e.preventDefault();
    onPressButtonControlPatients(id, mode, null).then((res) => {
      console.log(res);
      setLoading(true);
      /* Schimbam valoril de null cu cele din filtre daca este cazul*/
      getControlPatients(null, null, null).then((res) => {
        setPatients(res);
        setLoading(false);
      });
    });
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Pacienti neprogramati cu control recomandat
              </h1>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300 table-fixed">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                      >
                        Nume
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Vârstă
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Data consultatiei precedente
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Control peste
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Medic
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Diagnostic
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Telefon
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {patients?.map((patient) => (
                      <tr key={patient.id} className="even:bg-gray-50">
                        <Link to={"/employee/" + patient.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 cursor-pointer hover:text-blue-600 hover:underline">
                            {patient.name}
                          </td>
                        </Link>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {Math.floor(patient.age / 12)} ani și{" "}
                          {patient.age % 12} luni
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {patient.last_consult_date}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {patient.next_recommended}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {patient.doctor}
                        </td>
                        <td className="max-w-xl whitespace-normal px-3 py-4 text-sm text-gray-500">
                          {patient.diags.map((diag) => {
                            return (
                              <>
                                <p>{diag}</p>
                              </>
                            );
                          })}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {patient.phone}
                        </td>

                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                          <div className="flex gap-2">
                            <button
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                test();
                              }}
                              className="block rounded-md bg-[#409eff] px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Programat
                            </button>
                            <button
                              type="button"
                              onClick={(e) =>
                                onPressButton(
                                  e,
                                  patient.last_consult_id,
                                  "refuzat"
                                )
                              }
                              className="block rounded-md bg-[#e6a23c] px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Refuzat
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ControlPatientsTable;
