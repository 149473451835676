import React, { useState, useDeferredValue } from "react";
import {
  Header,
  NewPatientModal,
  Pagination,
  PatientsFilter,
  PatientsTable,
} from "../components";
import { nrPerPage } from "../utils/Constants";

const Patients = () => {
  const [pageNr, setPageNr] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [patientsCount, setPatientsCount] = useState(0);
  const [filterLabels, setFilterLabels] = useState({
    firstName: "",
    lastName: "",
    cnp: "",
    phone: "",
    email: "",
    consultationNumber: "",
  });
  const defferedFilterLabels = useDeferredValue(filterLabels);

  const onChangeFilter = (e) => {
    const { name, value } = e.target;
    setPageNr(1);
    setFilterLabels((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(filterLabels);
  };
  return (
    <>
      <Header />
      <NewPatientModal open={openModal} setOpen={setOpenModal} />
      <div className="px-20 mx-auto py-20">
        <div className="mx-auto">
          <PatientsFilter
            onChangeFilter={onChangeFilter}
            filterLabels={filterLabels}
            setFilterLabels={setFilterLabels}
          />
        </div>
        <div className="mt-20">
          <PatientsTable
            offset={(pageNr - 1) * nrPerPage}
            setPatientsCount={setPatientsCount}
            filterLabels={defferedFilterLabels}
            setOpenModal={setOpenModal}
          />
        </div>
        <div>
          <Pagination
            setPageNr={setPageNr}
            pageNr={pageNr}
            patientsCount={patientsCount}
          />
        </div>
      </div>
    </>
  );
};

export default Patients;
