import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPatientById } from "../api/Patient";

const PatientPersonalInfo = () => {
  const { id } = useParams();
  const [patient, setPatient] = useState();
  const [showInfo, setShowInfo] = useState({
    first_name: false,
    last_name: false,
    code: false,
    ci_serie: false,
    ci_number: false,
  });
  useEffect(() => {
    getPatientById(id).then((res) => {
      console.log(res);
      setPatient(res);
    });
  }, []);

  const changeSetInfo = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setShowInfo((prevState) => ({
      ...prevState,
      first_name: !prevState.first_name,
    }));
    console.log(e);
    console.log(value);
  };

  const onChangePersonalInfo = (event) => {
    const { name, value } = event.target;
    setPatient((prevState) => ({
      ...prevState,
      personal_information: {
        ...prevState.personal_information,
        [name]: value,
      }, // shallow copy all previous state
    }));
  };

  return (
    <>
      <div>
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Informații personale
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-4">
            <div className="pt-2 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Nume
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div
                  className={
                    (showInfo.first_name === false ? "" : "hidden ") +
                    "text-gray-900"
                  }
                >
                  {patient?.personal_information.first_name}
                </div>
                <div className={showInfo.first_name === true ? "" : "hidden "}>
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    onChange={onChangePersonalInfo}
                    value={patient?.personal_information.first_name}
                    className={
                      "pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    }
                  />
                </div>
                <button
                  type="button"
                  value="first_name"
                  onClick={(e) => changeSetInfo(e)}
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Modifică
                </button>
              </dd>
            </div>
            <div className="pt-2 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Prenume
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {patient?.personal_information.last_name}
                </div>
                <button
                  type="button"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Modifică
                </button>
              </dd>
            </div>
            <div className="pt-2 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                CNP
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {patient?.personal_information.code}
                </div>
                <button
                  type="button"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Modifică
                </button>
              </dd>
            </div>
            <div className="pt-2 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Serie CI
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {patient?.personal_information.ci_serie}
                </div>
                <button
                  type="button"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Modifică
                </button>
              </dd>
            </div>
            <div className="pt-2 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Numar CI
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {patient?.personal_information.ci_number}
                </div>
                <button
                  type="button"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Modifică
                </button>
              </dd>
            </div>
          </dl>
        </div>
        {/* Date de contact */}
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900 mt-10">
            Date de contact
          </h2>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-4">
            {patient?.contact.map((contact) => {
              return (
                <>
                  <div className="pt-2 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                      {contact.data_type === "phone" ? "Telefon" : "Email"}
                    </dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">{contact.data}</div>
                      <button
                        type="button"
                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                      >
                        Modifică
                      </button>
                    </dd>
                  </div>
                </>
              );
            })}
          </dl>
        </div>

        {/* Adresa */}
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900 mt-10">
            Adresa
          </h2>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-4">
            <div className="pt-2 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Tara
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {patient?.address.country_id}
                </div>
                <button
                  type="button"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Modifică
                </button>
              </dd>
            </div>
            <div className="pt-2 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Judet
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">{patient?.address.area_id}</div>
                <button
                  type="button"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Modifică
                </button>
              </dd>
            </div>
            <div className="pt-2 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Localitate
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">{patient?.address.city}</div>
                <button
                  type="button"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Modifică
                </button>
              </dd>
            </div>
            <div className="pt-2 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Adresa
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">{patient?.address.address}</div>
                <button
                  type="button"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Modifică
                </button>
              </dd>
            </div>
            <div className="pt-2 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Cod postal
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">{patient?.address.zip}</div>
                <button
                  type="button"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Modifică
                </button>
              </dd>
            </div>
            <div className="pt-2 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Mediu U/R
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {patient?.personal_information.ci_number}
                </div>
                <button
                  type="button"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Modifică
                </button>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};

export default PatientPersonalInfo;
