import React from "react";
import { Header, WaitingListTable } from "../components";

const WaitingList = () => {
  return (
    <>
      <Header />
      <div className="px-20">
        <WaitingListTable />
      </div>
    </>
  );
};

export default WaitingList;
