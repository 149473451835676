import axios from "axios";

export const getControlPatients = async (offset, limit, filterLabels) => {
  const res = await axios.get(
    `${process.env.REACT_APP_DEV_HOST}/consultations/nexts`,
    {
      /* Decomentam daca adaugam filtre la request*/
      // params: {
      //   offset: offset,
      //   limit: limit,
      //   first_name: filterLabels.lastName,
      //   last_name: filterLabels.firstName,
      //   code: filterLabels.cnp,
      // },
    }
  );
  return res.data;
};

export const onPressButtonControlPatients = async (id, mode, user_id) => {
  const res = await axios.post(
    `${process.env.REACT_APP_DEV_HOST}/consultations/logs`,
    {
      consult_id: id,
      mode: mode,
      user_id: 7,
    }
  );

  return res;
};

export const test = () => {
  let data = JSON.stringify({
    consult_id: 115329,
    mode: "programat77",
    user_id: 7,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://xh0bmjr09g.execute-api.eu-central-1.amazonaws.com/internal_serverless_lambda_stage/consultations/logs",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
};
