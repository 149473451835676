import React, { useEffect, useState } from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
} from "@heroicons/react/20/solid";
import { nrPerPage } from "../utils/Constants";
// const pages = Array.from({ length: 10 }, (_, i) => i + 1);
const Pagination = ({ setPageNr, pageNr, patientsCount }) => {
  const [pages, setPages] = useState([]);
  useEffect(() => {
    let pagesCount = parseInt(patientsCount) / nrPerPage + 1;
    console.log(pagesCount);
    setPages(Array.from({ length: pagesCount }, (_, i) => i + 1));
  }, [patientsCount]);

  return (
    <>
      <div>
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
          <div className="flex flex-1 justify-between sm:hidden">
            <a
              href="#"
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Previous
            </a>
            <a
              href="#"
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Next
            </a>
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Afisam de la{" "}
                <span className="font-medium">
                  {(pageNr - 1) * nrPerPage + 1}
                </span>{" "}
                la{" "}
                <span className="font-medium">
                  {Math.min(pageNr * nrPerPage, patientsCount)}
                </span>{" "}
                din <span className="font-medium">{patientsCount}</span>{" "}
                rezultate
              </p>
            </div>
            <div>
              <nav
                className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                aria-label="Pagination"
              >
                <a
                  href="#"
                  onClick={(e) => {
                    setPageNr(1);
                  }}
                  className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">First</span>
                  <ChevronDoubleLeftIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                    setPageNr(pageNr - 1);
                  }}
                  className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </a>
                {pages
                  .filter((page) => {
                    if (page > pageNr + 3 || page < pageNr - 3) {
                      return false;
                    }
                    return true;
                  })
                  .map((page) => {
                    return (
                      <a
                        href="#"
                        onClick={(e) => {
                          setPageNr(page);
                        }}
                        className={
                          page == pageNr
                            ? "relative inline-flex items-center px-4 py-2 text-sm font-semibold bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            : "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        }
                      >
                        {page}
                      </a>
                    );
                  })}

                <a
                  href="#"
                  onClick={(e) => {
                    setPageNr(pageNr + 1);
                  }}
                  className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                    setPageNr(pages[pages.length - 1]);
                  }}
                  className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Last</span>
                  <ChevronDoubleRightIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pagination;
