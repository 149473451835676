import React from "react";
import { Header, PatientPersonalInfo } from "../components";

const Patient = () => {
  return (
    <>
      <Header />
      <div className="grid grid-cols-3 px-20 mt-20">
        <PatientPersonalInfo />
      </div>
    </>
  );
};

export default Patient;
